// PC/SP両方で使用するスタイル
@use "../setting/config";

.sns-rhs__btns a {
  -webkit-transition: 0.1s ease 0.05s;
  -moz-transition: 0.1s ease 0.05s;
  -ms-transition: 0.1s ease 0.05s;
  transition: 0.1s ease 0.05s;

  &:hover {
    opacity: 0.5;
    text-decoration: none;
  }
}

.sns-rhs__fbbtn {
  display: flex;
  align-items: center;
  background: #1877f2;
  border-radius: 3px;
  color: #fff !important;
  padding: 10px;
  box-sizing: border-box;
  width: 90%;
  margin: 10px auto 0 auto;

  @include config.mq(sm) {
    width: 100%;
    margin: 10px 0 0 0;
  }

  .snsbt-icon {
    width: 40px;
    vertical-align: -10px;
    margin-right: 8px;
  }

  .snsbt-text {
    flex: 1;
  }

  span {
    font-size: 10px;
    font-weight: normal;
  }

  p {
    line-height: 1;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    padding: 10px;
  }
}

.sns-rhs__twbtn {
  display: flex;
  align-items: center;
  background: #000;
  border-radius: 3px;
  color: #fff !important;
  padding: 10px;
  box-sizing: border-box;
  width: 90%;
  margin: 10px auto 0 auto;

  @include config.mq(sm) {
    width: 100%;
    margin: 10px 0 0 0;
  }

  .snsbt-icon {
    width: 40px;
    margin-right: 8px;

    img {
      vertical-align: -10px;
    }
  }

  .snsbt-text {
    flex: 1;
  }

  span {
    font-size: 10px;
    font-weight: normal;
  }

  p {
    line-height: 1;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    padding: 10px;
    box-sizing: border-box;
  }
}

.m3p-rhs__snswrap a {

  &:hover {
    opacity: 0.5;
    text-decoration: none
  }
}
