@use "../setting/config";
@use "../setting/flex";

.m3pcom-recommended-panel {
  width: 100%;
  box-sizing: border-box;
  background: #FFF;
}

.m3pcom-recommended-title {
  width: 100%;
  padding: 0.5rem 1rem;
  background: config.$primary;
  color: #FFF;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1;

  @include config.mq(sm) {
    padding: 0.5rem 1rem;
  }

  @include config.mq(xl) {
    padding: 0.5rem 40px;
  }
}

.m3pcom-recommended-title--icon {
  width: 20px;
  height: 20px;
  position: relative;
  margin: 0 8px 0 0;
}

.m3pcom-recommended-bell {
  fill:#FFFFFF;
}

.m3pcom-recommended-notice {
  position: absolute;
  width: 12px;
  height: 12px;
  top: -3px;
  right: -2px;
  -webkit-animation: fadeInAnimation ease-in-out;
  animation: fadeInAnimation ease-in-out;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

.m3pcom-recommended-notice--red {
  fill: #C77171;
}

.m3pcom-recommended-notice--white {
  fill:#FFFFFF;
}

@keyframes fadeInAnimation {
  0% {
    transform: scale(0);
  }
  10% {
    transform: scale(0);
  }
  100% {
  transform: scale(1);
  }
}

.m3pcom-recommended-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.m3pcom-recommended-list--panel {
  padding: 0.5rem 1rem .5rem .5rem;

  @include config.mq(sm) {
    padding: 0.5rem 1rem .5rem .5rem;
  }

  @include config.mq(xl) {
    padding: 0.5rem 2.5rem .5rem 2rem;
    font-size: 1rem;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    list-style-position: inside;
    padding: 0 0 0 .5rem;

    &:hover {
    background: rgba(122, 190, 199, .2);
    border-radius:4px;
    }
  }
}

.m3pcom-recommended-list {
  font-size: 0.875rem;
  font-weight: 600;
}

.m3pcom-recommended-list--wrap {
  display: inline-block;
  cursor: pointer;
  width: 94%;

  @include config.mq(xl) {
    width: 97%;
  }
}

.m3pcom-recommended-new {
  color: #C77171;
  font-size: 0.75rem;
  font-weight: 900;
  margin: 0 3px;
  display: inline-block;
  white-space: nowrap;
}

.m3pcom-recommended-up {
  color: #5D905D;
  font-size: 0.75rem;
  font-weight: 900;
  margin: 0 3px;
  display: inline-block;
  white-space: nowrap;
}

.m3pcom-recommended-arr {
  -ms-flex-preferred-size: auto;
  -ms-flex-negative: 1;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
  height: 14px;
  margin: 0;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  width: 10px;
  line-height: 0;
}

.m3pcom-recommended-start {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  width: 90%;
}

.m3pcom-recommended-pickup--label {
  background: #c00;
  color: #fff;
  display: inline-block;
  font-size: .625rem;
  font-weight: 700;
  height: 16px;
  line-height: 1.2;
  margin: 0 8px 0 0;
  padding: 2px 4px;
  text-align: center;
  vertical-align: 3px;
}
