@use "../setting/config";
@use "../setting/flex";

.m3p-open-header__wrapper * {
  box-sizing: border-box;
}

.m3p-open-header__wrapper {
  height: 55px;
  width: 100%;
  background: #FFF;
  top: 0;
  position: fixed;
  z-index: 1000;
  transition-duration: .5s;
  transition-property: top;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 16%);
  border-bottom: 1px solid rgba(0,0,0,0.04);

  @include config.mq(md) {
    height: 40px;
  }
}

.m3p-open-header__wrapper.hide {
  top: -100px;
}

.m3p-open-header {
  transition: .5s;
  width: 100%;

  @include config.mq(md) {
    height: 40px;
  }
}

.m3p-open-header__content {
  position: relative;
  font-family: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ ProN W3", "Hiragino Sans", "ヒラギノ角ゴシック", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", sans-serif !important;
  font-size: 0.875rem;
  color: #555;
  background-color: #fff;
  height: 55px;
  z-index: 999;
  border-bottom: 1px solid rgba(0,0,0,0.04);
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include config.mq(md) {
    position: relative;
    width: 960px;
    margin: 0 auto;
    padding: 0 10px;
    height: 40px;
    border-bottom: none;
  }

  @include config.mq(xl) {
    width: 1180px;
    border-bottom: none;
  }
}

.m3p-open-header__inner {
  height: 55px;
  padding: .5rem 1rem;
  text-align: center;
  position: relative;

  @include config.mq(md) {
    padding: 5px 0;
    height: auto;
  }
}

ul.m3p-open-header__inner {
  list-style: none;
  font-size: 0;
  margin: 0;
  cursor: pointer;

  @include config.mq(md) {
    position: absolute;
    left: 0;
  }
}

.m3p-open-header__inner li {
  display: inline-block;

  @include config.mq(md) {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
  }
}

.m3p-open-header--logowrap {
  position: absolute;

  @include config.mq(md) {
    position: relative;
  }
}

.m3p-open-header__title {
  display: inline-block;
  margin: 0;
}

.m3p-open-header__logo {
  height: 16px;
  width: 63px;
  margin: 0 5px 3px 0;
  padding: 0;

  @include config.mq(md) {
    margin: 0 5px 0 0;
    padding: 0;
    height: 24px;
    width: 85px;
  }
}

.m3p-open-header__inner li .tex {
  height: 19px;
  margin-right: 5px;
  width: 62px;

  @include config.mq(md) {
    height: 24px;
    margin-right: 5px;
    width: 76px;
  }
}

.m3p-open-header__inner li .icon {
  height: 38px;
  width: 25px;

  @include config.mq(md) {
    height: 35px;
    width: 23px;
  }
}

.m3p-open-header__button {
  position: absolute;
  right: 0;
  padding: 3.5px 0;
}

.m3p-open-header__button ul {
  list-style: none;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  font-size: 0;
}

.m3p-open-header__button ul li {
  display: inline-block;
  vertical-align: middle;
  list-style: none;
  padding: 0 15px 0 0;
}

.m3p-open-header__button ul li.m3p-open-header__bunner {
  display: none;

  @include config.mq(md) {
    display: inline-block;
  }

  img {
    width: 185px;
    height: 32px;
  }
}

.m3p-open-header__bunner-inner {
  width: 100%;
}

.m3p-open-header__register {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 120px;
  height: 32px;
  background: #f9982c;
  color: #FFF !important;
  font-weight: 700;
  font-size: 12px;
  border-radius: 4px;
  padding: 0 5px;
}

.m3p-open-header__register svg {
  width: auto;
  height: 14px;
  margin: 9px 3px 9px 0;
  display: block;
  fill: currentcolor;
}

.m3p-open-header__register .m3p-open-header__register-text {
  margin: 9px 0;
  line-height: 1;
}

.m3p-open-header__register .m3p-open-header__register-inner {
  display: inline-block;
  background: #FFF;
  color: #f9982c;
  position: relative;
  text-align: center;
  font-size: 8px;
  line-height: 1;
  border-radius: 4px;
  margin: 2px 0 2px 5px;
  padding: 5px 3px;
}

.m3p-open-header__login {
  display: inline-block;
  border: 1px solid #40509e;
  color: #40509e;
  border-radius: 4px;
  font-size: 8px;
  padding: 5px;
  text-align: center;
  line-height: 1;
}

.m3p-open-header__login svg {
  width: 11px;
  height: 8.6px;
  fill: #40509E;
}

.m3pcom-open-atlas-body {
  background-color: #efefef;
  min-height: 600px;
  padding-top: 55px;
  padding-bottom: 10px;

  @include config.mq(xs) {
    padding-top: 55px;
  padding-bottom: 10px;
  }

  @include config.mq(md) {
    background-color: #f6f6f6;
    min-height: 600px;
    padding-top: 40px;
  }

  .atlas-main {
    width: 100%;
    float: none;

    @include config.mq(md) {
      width: 720px;
      float: left;
    }

    @include config.mq(xl) {
      width: 780px;
      float: left;
    }
  }

  .atlas-rhs {
    width: 100%;
    margin-left: 0;
    float: none;

    @include config.mq(md) {
      width: 230px;
      margin-left: 10px;
      float: left;
    }

    @include config.mq(xl) {
      width: 380px;
      margin-left: 20px;
    }
  }
}

.m3pcom-open-snippet-sp {
  display: block;

  @include config.mq(xs) {
    display: none;
  }
}

.m3pcom-open-snippet-pc {
  display: none;

  @include config.mq(xs) {
    display: block;
  }
}

.m3pcom-open-thread-wrap {
  width: 100%;
  position: relative;

  img {
    width: 100%;
    display: block;
  }
}

.m3pcom-open-thread-inner {
  position: absolute;
  background: rgba(249, 250, 251, 1);
  border-radius: 4px;
  width: 90%;
  text-align: center;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  padding: 1rem;

  @include config.mq(xs) {
    padding: 2rem 0;
    top: 40%;
  }

  @include config.mq(md) {
    padding: 2rem 0;
    top: 50%;
  }
}

.m3pcom-open-thread-panel {
  padding: 1rem 0;
}

.m3pcom-open-thread-text {
  font-weight: 700;
  line-height: 1.35;
  font-size: 12px;
  margin: 0 auto 1rem auto;

  @include config.mq(xs) {
    font-size: 14px;
  }
}

.m3p-text-danger {
  color: #c00;
}

.m3pcom-open-thread-subtetxt {
  line-height: 1.35;
  font-size: 12px;
  margin: 0 auto 1rem auto;
  padding: 0;

  @include config.mq(xs) {
    font-size: 14px;
  }
}

.m3pcom-open-thread-login {
  line-height: 1.35;
  font-size: 14px;
}

.m3pcom-open-thread-button {
  margin: 0 auto 1rem auto;
  font-weight: 700;
  font-size: 1rem;
  width: 80%;

  @include config.mq(sm) {
    width: 50%;
  }
}

.m3pcom-open-bg {
  background: #FFFCEE;
}

.m3pcom-open-bg--white {
  background: #FFF;
}

.m3pcom-open-bg--blue {
  background: #e6f3f4;
}

.m3pcom-open-text {
  color: #2FABBC;
}

.m3pcom-primary-text {
  color: #2FABBC;
}

.m3pcom-open-marker {
  background:linear-gradient(transparent 60%, #ffe988 60%);
}

.m3pcom-open-header {
  img {
    width: 100%;
    height: auto;
  }
}

.m3pcom-open-panel {
  padding: 1.5rem 1rem;

  @include config.mq(sm) {
    padding: 1.5rem 2.5rem;
  }
}

.m3pcom-open-list--wrap {
  margin: 10px 0 0 0;
  padding: 0 1rem;

  @include config.mq(sm) {
    padding: 0 0;
  }
}

.m3pcom-open-listpanel {
  background: #7ABEC7;
  border-radius: 20px;
  padding: 1rem 4px 4px 4px;
}

.m3pcom-open-heading {
  -ms-flex-preferred-size: auto;
  -ms-flex-negative: 1;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  border-left: 5px solid #FFF9F0;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
  font-size: 1.1875rem;
  font-weight: 700;
  margin: 0 0 1rem 1rem;
  padding: 0 8px;
}

.m3pcom-open-title--label {
  display: inline-block;
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  height: 16px;
  color: rgb(255, 255, 255);
  vertical-align: 3px;
  padding: 2px 4px;
  background: #c00;
  margin: 0px 8px;
}

.m3pcom-open-border--top {
  border-top: 1px solid #DEDEDE;
}

.m3pcom-open-list {
  margin: 0;
  list-style: none;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  padding: 0 1rem 0 1rem;

  @include config.mq(sm) {
    padding: 0 2.5rem 0 2.5rem;
  }
}

.m3pcom-open-list--cell {
  border-bottom: 1px dashed #DEDEDE;
  padding: 1rem 0;
  font-weight: 700;
  font-size: 1rem;

  &:last-child {
    border-bottom: 1px dashed transparent;
  }
}

.m3pcom-open-register--panel {
  padding: 1.5rem 1rem;
  background: #FFF;
  text-align: center;

  @include config.mq(sm) {
    padding: 1.5rem 2.5rem;
  }
}

.m3pcom-open-register--text {
  font-size: 1rem;
  color: #333;
  font-weight: 700;
  margin: 0 auto 1.5rem auto;
}

.m3pcom-open-register--login {
  font-size: 1rem;
}

.m3pcom-open-register--button {
  font-size: 1rem;
  font-weight: 700;
  margin: 0 auto 1rem;
  padding: 1rem 0;
  width: 90%;

  @include config.mq(sm) {
    font-size: 1.25rem;
    padding: 2rem 0;
    width: 50%;
  }
}

.m3pcom-open-panel--long {
  @include flex.Flex;
  flex-direction: column;
  padding: 1.5rem 1rem;

  @include config.mq(sm) {
    padding: 5rem 2.5rem;
  }
}

.m3pcom-open-panel--longFirst {
  @include flex.Flex;
  flex-direction: column;
  padding: 2rem 1rem 2rem 1rem;

  @include config.mq(sm) {
    padding: 5rem 2.5rem 2.5rem 2.5rem;
  }
}

.m3pcom-open-panel--longEnd {
  @include flex.Flex;
  flex-direction: column;
  padding: 2rem 1rem 2rem 1rem;

  @include config.mq(sm) {
    padding: 2.5rem 2.5rem 5rem 2.5rem;
  }
}

.m3pcom-open-panel--short {
  @include flex.Flex;
  flex-direction: column;
  padding: 2rem 1rem 2rem 1rem;

  @include config.mq(sm) {
    padding: 2.5rem;
  }
}

.m3pcom-open-panel--wide {
  @include flex.Flex;
  flex-direction: column;
  padding: 1.5rem 0 0 0;
  background: #FFF;

  @include config.mq(sm) {
    padding: 5rem 2.5rem 0 2.5rem;
  }
}

.m3pcom-open-panel--bottomless {
  @include flex.Flex;
  flex-direction: column;
  padding: 1.5rem 0 0 0;

  @include config.mq(sm) {
    padding: 1.5rem 2.5rem 0 2.5rem;
  }

  @include config.mq(md) {
    background: url(./images/open_app_bg.png) no-repeat bottom center;
    background-size: contain;
  }

  img {
    width: 100%;
    margin: 0 auto;
    display: block;

    @include config.mq(md) {
      width: 70%;
    }
  }
}

h1.m3pcom-open-h1 {
  position: relative;
  font-size: 1.25rem;
  margin: 0 auto 1.5rem auto;

  @include config.mq(sm) {
    font-size: 2rem;
  }
}

.m3pcom-open-h1--01 {

  &::before {
    content: " ";
    display: inline-block;
    position: absolute;
    width: 62px;
    height: 2px;
    background-color: #000;
    top: 35px;
    transform: translate(-50%,-50%) rotate(65deg);
    left: -20px;
  }

  &::after {
    content: " ";
    display: inline-block;
    position: absolute;
    width: 62px;
    height: 2px;
    background-color: #000;
    top: 35px;
    transform: translate(-50%,-50%) rotate(-65deg);
    right: -80px;
  }
}

.m3pcom-open-h1--02 {

  &::before {
    content: " ";
    display: inline-block;
    position: absolute;
    width: 62px;
    height: 2px;
    background-color: #000;
    top: 35px;
    transform: translate(-50%,-50%) rotate(65deg);
    left: 0px;

    @include config.mq(md) {
      width: 96px;
      top: 45px;
    }
  }

  &::after {
    content: " ";
    display: inline-block;
    position: absolute;
    width: 62px;
    height: 2px;
    background-color: #000;
    top: 35px;
    transform: translate(-50%,-50%) rotate(-65deg);
    right: -60px;

    @include config.mq(md) {
      width: 96px;
      top: 45px;
      right: -100px;
    }
  }
}

.m3pcom-open-h1--03 {

  &::before {
    content: " ";
    display: inline-block;
    position: absolute;
    width: 62px;
    height: 2px;
    background-color: #000;
    top: 35px;
    transform: translate(-50%,-50%) rotate(65deg);
    left: -20px;

    @include config.mq(md) {
      top: 45px;
      width: 96px;
      left: -30px;;
    }
  }

  &::after {
    content: " ";
    display: inline-block;
    position: absolute;
    width: 62px;
    height: 2px;
    background-color: #000;
    top: 35px;
    transform: translate(-50%,-50%) rotate(-65deg);
    right: -80px;

    @include config.mq(md) {
      width: 96px;
      top: 45px;
      right: -130px;
    }
  }
}


h2.m3pcom-open-h2 {
  font-size: 1rem;
  margin: 0 auto 1.5rem auto;
  color: #000;

  @include config.mq(sm) {
    font-size: 1.5rem;
  }
}

.m3pcom-open-sec1--col {

  .m3pcom-open-sec1--icon {
    width: 65px;
    height: auto;
    margin: 0 auto 1rem auto;
    display: block;
  }

  p {
    font-size: 0.9375rem;
    text-align: left;
    margin: 0 0 1.5rem 0;
  }
}

.m3pcom-open-sec1--panel {
  position: relative;
  width: 100%;
  background: #FFF;
  border: 1px solid #DEDEDE;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.14);
  padding: 1.5rem 1rem;
  margin: 0 auto 1.5rem auto;

  p {
    margin: 0;
    letter-spacing: 1px;
  }
}

.m3pcom-open-sec1--point {
  position: absolute;
  top: -16px;
  left: 30px;
  width: 38px;
  height: 38px;
}

.m3pcom-open-astarisk {
  margin: 0 auto 0 0;
  font-size: 0.625rem;
  color: #999;
}

.m3pcom-open-astarisk--margin {
  margin: .5rem auto 0 0;
  font-size: 0.625rem;
  color: #999;
}

.m3pcom-open-sec2--wrap {
  @include flex.Flex;
  align-items: center;
  margin: 0 auto 1.5rem auto;

  &:last-child {
    margin: 0 auto;
  }
}

.m3pcom-open-sec2--left {
  flex-direction: row-reverse;
}

.m3pcom-open-sec2--reverse {
  flex-direction: row-reverse;

    @include config.mq(md) {
    flex-direction: row;
  }
}

.m3pcom-open-sec2--img {
  margin: 0 -1rem 0 auto;
  img {
    width: 76px;
    height: 76px;
  }
}

.m3pcom-open-sec2--img--re {
  margin: 0 -1rem 0 auto;
  @include config.mq(md) {
    margin: 0 auto 0 -1rem;
  }
}

.m3pcom-open-sec2--text {
  width: 100%;
  background: #FFF;
  padding: 1rem 1.5rem 1rem 2.5rem;
  font-size: 0.875rem;
  color: #000;
  text-align: left;
  border-radius: 20px;
  letter-spacing: 1px;

  @include config.mq(md) {
    width: 500px;
  }
}

.m3pcom-open-sec2--text--re {
  padding: 1rem 1.5rem 1rem 2.5rem;

  @include config.mq(md) {
    padding: 1rem 1.5rem 1rem 5.7rem;
  }
}

.m3pcom-open-h1--min {
  font-size: 0.875rem;

  @include config.mq(md) {
    font-size: 1.5rem;
  }
}

.m3pcom-open-sec3-img {
  width: 100%;
  overflow: hidden;
  position: relative;

  @include config.mq(xs) {
    width: 450px;
  }

  @include config.mq(lg) {
    width: 100%;
  }

  .m3pcom-open-sec3-img--inner {
    margin: 0 auto;
    width: 1100px;

    @include config.mq(lg) {
      width: 1800px;
    }

    @include config.mq(lg) {
      width: 2000px;
    }
  }

  .slider {
    width: 108%;
    display: flex;
    align-items: flex-start;
    overflow: hidden;
    padding: 0;
    margin: 0;

    li {
      width: 34%;
      display: flex;
      align-items: flex-start;
      animation-name: phmeritslider;
      animation-duration: 10s;
      animation-fill-mode: backwards;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    .slide-01, .slide-02, .slide-03 {
      width: 98%;
      height: auto;
      flex: none;
      vertical-align: bottom;
    }

    .slide-01 {
      animation-delay: 0s;
    }

    .slide-02 {
      animation-delay: 5s;
    }
  }
}
@keyframes phmeritslider{
  0% {
  transform: translateX(0);
  }
  40% {
  transform: translateX(0);
  }
  50% {
  transform: translateX(-100%);
  }
  90% {
  transform: translateX(-100%);
  }
  100% {
  transform: translateX(-200%);
  }
}

h1.m3pcom-open-image {
  margin: 0;
}

.m3pcom-keyword-pcommunity-hidden-view{
  display: none;
}
input[type="checkbox"]#m3pcom-keyword-pcommunity-more-trigger{
  display: none;
}
input[type="checkbox"]#m3pcom-keyword-pcommunity-more-trigger:checked ~ .m3pcom-keyword-pcommunity-hidden-view{
  display: block;
}
input[type="checkbox"]#m3pcom-keyword-pcommunity-more-trigger:checked ~ .m3pcom-keyword-pcommunity-more{
  display: none;
}

.m3pcom-keyword-news-articles-hidden-view{
  display: none;
}
input[type="checkbox"]#m3pcom-keyword-news-articles-more-trigger{
  display: none;
}
input[type="checkbox"]#m3pcom-keyword-news-articles-more-trigger:checked ~ .m3pcom-keyword-news-articles-hidden-view{
  display: block;
}
input[type="checkbox"]#m3pcom-keyword-news-articles-more-trigger:checked ~ .m3pcom-keyword-news-articles-more{
  display: none;
}
