@use "../setting/config";
@use "../setting/flex";
@use "../mixin/panel";

.m3pcom-keyword-h1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: .5rem;
    font-size: 1.1875rem;
    font-weight: 700;
    margin: 0;
    padding: 0;
}
.pcomm-keyword-h1__point {
  width: auto;
  height: 40px;
}

.keyh1{fill:#006874;}
.keyh2{fill:#FFFFFF;}

.m3pcom-keyword-keyv__wrap {
  width: 100%;

  figure {
    margin: 0;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  h1 {
    margin: 0 auto;
  }
}

.m3pcom-keyword-thread__title h3 {
  display: inline;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  color: config.$gray;

  @include config.mq(sm) {
    font-size: 1.125rem;
  }

  &:hover {
    text-decoration: none!important;
  }
}

.m3pcom-keyword-thread {
  display: block;
  width: 100%;
  padding: 1rem 1rem;
  background: #FFF;
  box-sizing: border-box;
  background: rgba(255, 2555, 255, 1);
  transition-duration: 0.4s;
  -webkit-transition-duration: .4s;
  -o-transition-duration: 0.4s;
  cursor: pointer;

  &:hover {
    background: config.$primary-l-h;
  }

  @include config.mq(sm) {
    padding: 1rem 1rem;
  }

  @include config.mq(xl) {
    padding: 1rem 40px;
  }
}

.m3pcom-keyword-sub__wrap {
  margin: 0 auto .2rem auto;
}

.m3pcom-keyword-text__wrap {
  position: relative;
  margin: 0 auto;

  a {
    color: #03a!important;
    font-weight: 700;
    cursor: pointer;
  }

  p {
    margin: 1em 0;

    &:nth-last-child(3) {
      margin: 1em 0 0 0;
    }
  }
}

.m3pcom-keyword-list__panel {
  width: 100%;
  background: #fff;
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    margin: 0;
  }
}

.m3pcom-keyword-list {
  border-bottom: 1px solid #eff3f4;

  &:last-child {
    border: none;
  }
}

li.m3pcom-keyword-list {
    list-style: none;
}

.m3pcom-keyword-news__wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  gap: 10px;
  cursor: pointer;
  background: #fff;


  @include config.mq(sm) {
    padding: 1rem 1rem;
    flex-direction: row;
  }

  @include config.mq(xl) {
    padding: 1rem 40px;
    flex-direction: row;
  }

  &:hover {
  background: config.$primary-l-h;
  }
}

.m3pcom-keyword-news__textwrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 2;
  gap: 10px;
  cursor: pointer;
}


.m3pcom-keyword-news__day {
  color: #999;
  font-size: .75rem;
  line-height: 1.2;
  white-space: nowrap;
}

.m3pcom-keyword-news__text {
  h3 {
    margin: 0;
  }

  p {
    margin: .5rem auto 0 auto;
  }
}

.m3pcom-keyword-news__title {
  color: #03a;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
}

.m3pcom-keyword-news__summary {
  font-size: 0.85rem;
}

.m3pcom-keyword-news__thumbnail {
  img {
    display: block;
  }
}

.m3pcom-keyword-news__link {
  font-size: 0.85rem;
  color: #03a;
  margin: 0 0 0 .3rem;
}

.m3pcom-keyword-news__author {
  color: #999;
  font-size: .75rem;
  line-height: 1.2;
  white-space: nowrap;
}

.m3pcom-keyword-column__wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  gap: 10px;
  cursor: pointer;
  background: #fff;


  @include config.mq(sm) {
    padding: 1rem 1rem;
  }

  @include config.mq(xl) {
    padding: 1rem 40px;
  }

  &:hover {
  background: config.$primary-l-h;
  }
}

.m3pcom-keyword-column__item {
  flex-grow: 2;
}

.m3pcom-keyword-column__title {
  display: inline-block;
}


.m3pcom-keyword-column__title h3 {
  display: inline;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  color: config.$gray;

  @include config.mq(sm) {
    font-size: 1rem;
  }

  &:hover {
    text-decoration: none!important;
  }
}

.m3pcom-keyword-column__description {
  display: block;
  font-size: .875rem;
  margin: 10px 0 0 0;
}

.m3pcom-keyword-column__thumbnail {
  position: relative;
  padding: 80px 80px 0 0;
  border: 1px solid #eee;
}

.m3pcom-keyword-column__thumbnail img {
  display: block;
  position: absolute;
  height: 80px;
  width: auto;
  top: 50%;
  left: 50%;
  clip: rect(auto, 93px, auto, 13px);
  transform: translate(-50%, -50%);
}

.m3pcom-keyword-panel {
  width: 100%;
  background: #FFF;
  color: #051f23;
  padding: 1.875rem 1rem 1.875rem 1rem;
  margin: 0;

  @include config.mq(sm) {
    padding: 1rem 1rem 1.875rem 1rem;
  }

  @include config.mq(xl) {
    padding: 1.875rem 2.5rem 1.875rem 2.5rem;
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.25rem 1rem;
  }
}

.m3pcom-keyword-h2 {
  background: #FFF;
  color: #333;
}

.m3pcom-keyword-heading {
  display: flex;
  border-left: 5px solid #2fabbc;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
  font-size: 1.1875rem;
  font-weight: 700;
  margin: 0;
  padding: 0 8px;
}

.m3pcom-keyword-tag {

  li {
    list-style: none;
    font-weight: 400;
    cursor: pointer;

    &:hover {
        opacity: .5;
      }

    a {
      color: #051f23;
      font-size: 1rem;
      cursor: pointer;
      background: rgba(205, 231, 236, .6);
      padding: .6rem 1rem;
      border-radius: 8px;
      white-space: nowrap;

        &::before {
        content: "#";
        margin: 0 .25rem 0 0;
      }
    }
  }
}

.m3pcom-listnavi-wrap {
  height: 68px;

  @include config.mq(sm) {
    height: 60px;
  }
}

h2.m3pcom-open-service__title {
  position: relative;
  font-size: 1.25rem;
  margin: 0 auto 1.5rem auto;

  @include config.mq(sm) {
    font-size: 2rem;
  }
}

.m3pcom-open-service__title--01 {

  &::before {
    content: " ";
    display: inline-block;
    position: absolute;
    width: 62px;
    height: 2px;
    background-color: #000;
    top: 35px;
    transform: translate(-50%,-50%) rotate(65deg);
    left: -20px;
  }

  &::after {
    content: " ";
    display: inline-block;
    position: absolute;
    width: 62px;
    height: 2px;
    background-color: #000;
    top: 35px;
    transform: translate(-50%,-50%) rotate(-65deg);
    right: -80px;
  }
}

.m3pcom-open-service__title--02 {

  &::before {
    content: " ";
    display: inline-block;
    position: absolute;
    width: 62px;
    height: 2px;
    background-color: #000;
    top: 35px;
    transform: translate(-50%,-50%) rotate(65deg);
    left: 0px;

    @include config.mq(md) {
      width: 96px;
      top: 45px;
    }
  }

  &::after {
    content: " ";
    display: inline-block;
    position: absolute;
    width: 62px;
    height: 2px;
    background-color: #000;
    top: 35px;
    transform: translate(-50%,-50%) rotate(-65deg);
    right: -60px;

    @include config.mq(md) {
      width: 96px;
      top: 45px;
      right: -100px;
    }
  }
}

.m3pcom-open-service__title--03 {

  &::before {
    content: " ";
    display: inline-block;
    position: absolute;
    width: 62px;
    height: 2px;
    background-color: #000;
    top: 35px;
    transform: translate(-50%,-50%) rotate(65deg);
    left: -20px;

    @include config.mq(md) {
      top: 45px;
      width: 96px;
      left: -30px;;
    }
  }

  &::after {
    content: " ";
    display: inline-block;
    position: absolute;
    width: 62px;
    height: 2px;
    background-color: #000;
    top: 35px;
    transform: translate(-50%,-50%) rotate(-65deg);
    right: -80px;

    @include config.mq(md) {
      width: 96px;
      top: 45px;
      right: -130px;
    }
  }
}

.m3pcom-open-service__title--min {
  font-size: 0.875rem;

  @include config.mq(md) {
    font-size: 1.5rem;
  }
}

.m3pcom-open-image img {
  display: block;
}

.m3pcom-keyword-bunner img {
  width: 100%;
}
