@use "../setting/config";
@use "../setting/flex";
@use "../mixin/panel";

.m3pcom-thread-panel {
  margin-top: 10px;
}

.m3pcom-thread-category--wrap {
  margin: 0 auto 3px 0;
}

.m3pcom-threa-title--wrap {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.m3pcom-thread-title {
  display: inline;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  color: config.$gray;

  @include config.mq(sm) {
    font-size: 1.125rem;
  }

  &:hover {
    text-decoration: none!important;
  }
}

.m3pcom-thread-sub--ellipsis {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: config.$g-light;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}

.m3pcom-thread-sub {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: config.$g-light;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  white-space: nowrap;
}

.m3pcom-thread-sub--line {
  margin: 0 3px;
  height: 14px;
  background-color: #E7E7EA;
  width: 1px;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}

.m3pcom-thread-sub--wrap {
  margin: 0 auto 1.25rem auto;
}

.m3pcom-thread-text--wrap {
  position: relative;
  margin: 0 auto 0.625rem 0;

  a {
    color: #03a!important;
    font-weight: 700;
    cursor: pointer;
  }

  p {
    margin: 1em 0;

    &:nth-last-child(3) {
      margin: 1em 0 0 0;
    }
  }
}

.m3pcom-thread-text--hover {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.m3pcom-thread-link {
  font-size: 0.875rem;
  font-weight: 700;
  color: config.$primary;
}

.m3pcom-thread-panel {
  background: rgba(255, 2555, 255, 1);
  transition-duration: 0.4s;
  -webkit-transition-duration: .4s;
  -o-transition-duration: 0.4s;

    &:hover {
      background: config.$primary-l-h;
  }
}

.js-accordionArea[data-accordion-status="hide"] {
  display: none;
}
.js-accordionArea[data-accordion-status="show"] {
  display: block;
}

// reactionここから

.m3pcom-thread-reaction--wrap {
  position: relative;
  padding: 0.675rem 0;
}
