@use "../setting/config";

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

.m3pcom-auto--center {
  margin: 0 auto;
}

.m3pcom-center {
  text-align: center;
}

.m3pcom-maintext {
  font-size: 0.9375rem;
  line-height: 1.75;
  font-weight: 500;
}

.m3pcom-push-top10 {
  margin-top: 10px;
}

.m3pcom-emphasis {
  font-weight: 700;
}

.m3pcom-border-top {
  border-top: 1px solid #EFF3F4;
}

.m3pcom-atlas-sp-body {
  background-color: #efefef;
  min-height: 600px;
  padding-bottom: 10px;

  @include config.mq(xs) {
    padding-bottom: 10px;
  }
}

.m3pcom-atlas-m3comapp-body {
  padding-top: 10px;
}

.m3pcom-atlas-body {
  background-color: #f6f6f6;
  min-height: 600px;
  padding-top: 0px;
}

.m3pcom-text--most {
  color: #c00;
  font-style: normal;
  font-weight: 700;
}

.m3pcom-text--link {
  color: #03a;

  &:hover {
    opacity: .7;
  }
}

.m3pcom-display-pc {
  display: none;

  @include config.mq(xs) 
  {
    display: block;
  }
}

.m3pcom-display-sp {
  display: block;

  @include config.mq(xs) {
    display: none;
  }
}
