@use "../setting/config";
@use "../setting/flex";

@mixin naviFlex {
  display: flex;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@mixin modalL {
  width: 100%;
  background: #FFF;
  padding: 2rem 1.5rem;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: relative;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  @include config.mq(md) {
    width: 500px;
  }
}

@mixin modalM {
  width: 100%;
  background: #FFF;
  padding: 2rem 1.5rem;
  max-width: 0 auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: relative;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  @include config.mq(xs) {
    width: 350px;
  }
}

.m3pcom-modal-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: #000;
}

.m3-twbs-modal-backdrop.in {
  opacity: 0.25;
  filter: alpha(opacity=25);
}

.m3pcom-modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9001;
  overflow-x: hidden;
  overflow-y: auto;

  * {
    box-sizing: border-box;
  }
}

.m3pcom-modal-dialog--l {
  position: absolute;
  margin:4rem auto 0 auto;
  z-index: 8001;
  top: 0;
  left: 18px;
  right: 18px;
  height: calc(100% - 160px);

  @include config.mq(md) {
    max-width: 500px;
    margin:100px auto 0 auto;
    left: 72px;
    right: 72px;
    height: calc(100% - 240px);
  }
}

.m3pcom-modal-dialog--jsl {
  position: absolute;
  margin:auto auto;
  z-index: 8001;
  top: 0;
  left: 18px;
  right: 18px;
  height: 430px;

  @include config.mq(md) {
    width: 430px;
    margin:auto auto;
  }
}

.m3pcom-modal-dialog--m {
  position: relative;
  width: 300px;
  margin:300px auto;
  z-index: 8001;
}

.m3pcom-modal-dialog--jsm {
  position: relative;
  width: 300px;
  margin:auto auto;
  z-index: 8001;
}

.m3pcom-modal-l * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.m3pcom-modal-l {
  @include modalL;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: auto;
  height: calc(100% - 0px);

  form {
    width: 100%
  }

  @include config.mq(md) {
    height: calc(100% - 0px);
  }
}

.m3pcom-modal-l--short * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.m3pcom-modal-l--short {
  @include modalL;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: auto;
  height: 430px;

  form {
    width: 100%
  }

  @include config.mq(md) {
    height: 430px;
  }
}

.m3pcom-modal-heading--ca {
  @include naviFlex;
  background: #FFF;
  padding: 0.5rem 0;
}

.m3pcom-modal-heading {
  @include naviFlex;
  background: #FFF;
  padding: 0 0 1.5rem 0;
}

.m3pcom-modal-heading--h2 {
  border-left: 5px solid config.$primary;
  font-size: 1rem;
  padding: 0 8px;
  margin: 0;
  @include flex.FlexItem;
}

.m3pcom-modal-title {
  font-size: 0.875rem;
  font-weight: 700;
}

.m3pcom-modal-text {
  font-size: 0.875rem;
  font-weight: 500;
}

.m3pcom-modal-text input {
  width: 100%;
  font-size: 14px;
  border: 1px solid #999999;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 0.5rem;
  height: 1.75rem;
  align-items: center;
  -ms-flex-align: center;
}

.m3pcom-modal-wrap {
  margin: 0 0 .5rem 0;
}

.m3pcom-modal-btnwrap {
  margin: 2rem auto;
  text-align: center;
}

@mixin textArea {
  -webkit-appearance: none;
  display: block;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 .5rem;
  border: 1px solid config.$g-light;
  border-radius: 4px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  margin: 0;
  cursor: pointer;
  -webkit-transition: border-color .2s ease,outline .2s ease;
  transition: border-color .2s ease,outline .2s ease;
}

.m3pcom-modal-pulldown {
  width: 100%;

  select {
    @include textArea;
    height: 1.75rem;
    background: url(./images/arr_down.svg) no-repeat;
    background-position: right 8px top 6px;
    background-size: 15px;
    padding: 0 2rem 0 0.5rem;
  }
}

.m3pcom-modal-textarea {
  width: 100%;

  input {
    @include textArea;
    height: 1.75rem;
  }

  textarea {
    @include textArea;
    resize: vertical;
    height: 7rem;
    padding: .7rem;
  }
}

.m3pcom-modal-atention--s {
  font-size: 0.625rem;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;

  a {
    color: config.$b-link;
  }
}

.m3pcom-modal-atention--m {
  font-size: 0.625rem;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;

  a {
    color: config.$b-link;
  }
}

.m3pcom-modal-btnwrap--top {
  margin: 1rem auto 0 auto;
  text-align: center;
}

.m3pcom-modal-m {
  @include modalM;
}

.m3pcom-modal-flexwrap {
  @include naviFlex;
  width: 100%;
  margin: 0 calc(0.65rem / 2 * -1);
}

.m3pcom-modal-btn--right {
  margin: 0 0.625rem 0 0;
}

.m3pcom-modal-btn--swap {
  display:none;
}

.m3pcom-modal-title--bottom {
  font-size: .875rem;
  font-weight: 700;
  margin: 0 auto 1rem auto;
  text-align: center;
  @include flex.FlexItem;
}

.m3pcom-js-modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6666;
  display: none;
  width: 100%;
  height: 120%;
  background-color: rgba(0,0,0,0.25);
}

.m3pcom-js-modal-box {
  position: fixed;
  z-index: 7777;
  width: 90%;
  display: none;
  height: 430px;

  @include config.mq(md) {
    height: 430px;
    width: 500px;
  }
}

.m3pcom-modal-effect * {
  box-sizing: border-box;
}

.m3pcom-js-modal-box.m3pcom-fade .m3pcom-modal-effect  {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}


.m3pcom-js-modal-box.m3pcom-in .m3pcom-modal-effect {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.m3pcom-modal-error {
  width: 100%;
}

.m3pcom-modal-error--message {
  list-style: none;
  padding: 0.5rem;
  color: #c00;
  border: 1px solid #C00;
  border-radius: 4px;
  font-size: 0.875rem;
  width: 100%;
}
