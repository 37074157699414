@use "../setting/config";
@use "../setting/flex";

@mixin naviFlex {
  display: flex;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@mixin pBtn($color: #FFF, $padding: 0) {
  background:config.$primary-l;
  color: $color;
  padding: $padding;
  font-weight: 700;
  text-align: center;
  line-height: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  transition-duration: 0.4s;
  -webkit-transition-duration: .4s;
  -o-transition-duration: 0.4s;
  border: 2px solid config.$primary-l;

  .b-icon {
    fill: #FFF;
  }

  &:visited {
    color: #FFF!important;
  }

  &:hover {
    background: #FFF;
    color: config.$primary-l!important;
    border: 2px solid config.$primary-l;
    text-decoration: none!important;

    .b-icon {
      fill: config.$primary-l;
    }
  }
}

@mixin pBtnm($color: #FFF, $padding: 0) {
  background:config.$primary-l;
  color: $color;
  padding: $padding;
  font-weight: 700;
  text-align: center;
  line-height: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  transition-duration: 0.4s;
  -webkit-transition-duration: .4s;
  -o-transition-duration: 0.4s;
  border: 1px solid config.$primary-l;

  .b-icon {
    fill: #FFF;
  }

  &:visited {
    color: #FFF!important;
  }

  &:hover {
    background: #FFF;
    color: config.$primary-l!important;
    border: 1px solid config.$primary-l;
    text-decoration: none!important;

    .b-icon {
      fill: config.$primary-l;
    }
  }
}

.m3pcom-btn-primary--l {
  width: 200px;
  height: 45px;
  font-size: 0.875rem;
  border-radius: 30px;
  cursor: pointer;
  @include pBtn
}

a.m3pcom-btn-primary--l {
  color: #FFF!important;
}

@mixin pBtnicon {
  margin: 0 3px 0 0;
}

.m3pcom-btn-wcomm--l {
  width: 19px;
  height: 20px;
  @include pBtnicon;
}

.m3pcom-btn-pen {
  width: 18px;
  height: 20px;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  @include pBtnicon
}

.m3pcom-btn-primary--m {
  width: 128px;
  height: 24px;
  font-size: 0.875rem;
  border-radius: 20px;
  cursor: pointer;
  @include pBtnm
}

a.m3pcom-btn-primary--m {
  color: #FFF!important;
}

.m3pcom-btn-wcomm--m {
  width: 12.8px;
  height: 12px;
  @include pBtnicon
}

.m3pcom-btn-white {
  background: #FFF;
  color: config.$primary-l;
  padding: 0;
  font-weight: 700;
  text-align: center;
  line-height: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 148px;
  height: 30px;
  font-size: 0.875rem;
  border-radius: 20px;
  transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  -webkit-transition-duration: .4s;

  &:hover {
    background: config.$primary-l;
    color: #FFF!important;
    text-decoration: none!important;

    .w-icon {
      fill: #FFF;
    }
  }
}

.m3pcom-btn-arr {
  width: 10px;
  height: 14px;
  margin: 0 0 0 8px;
  transform: rotate(-90deg);
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}

.w-icon {
  fill: config.$primary-l;
}

.m3pcom-btn-shadow {
  -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
}

.m3pcom-btn-gray {
  background:#DCDCDC;
  color: config.$gray;
  padding: 0;
  font-weight: 700;
  text-align: center;
  line-height: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 2px solid #DCDCDC;
  width: 200px;
  height: 45px;
  font-size: 0.875rem;
  border-radius: 30px;
}

.m3pcom-btn-more {
  background:#FFF;
  color: config.$gray;
  padding: 0;
  font-weight: 700;
  text-align: center;
  line-height: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  transition-duration: 0.4s;
  -webkit-transition-duration: .4s;
  -o-transition-duration: 0.4s;
  width: 100%;
  height: 60px;
  font-size: 0.875rem;

  &:hover {
    span {
      opacity: .6;
    }
  }
}

.m3pcom-btn-more--arr {
  width: 14px;
  height: 10px;
  margin: 0 0 0 8px;
  @include flex.FlexItem;
}

@mixin m3Button {
  cursor: pointer;
  padding: 8px 0;
  border-radius: 4px;
  text-decoration: none;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  font-size: 0.9375rem;
  line-height: 1.5;
  white-space: nowrap;
  -webkit-transition: background 0.2s ease 0.1s;
  -moz-transition: background 0.2s ease 0.1s;
  -ms-transition: background 0.2s ease 0.1s;
  transition: background 0.2s ease 0.1s;
}

.m3pcom-btn--default {
  @include naviFlex;
  @include m3Button;
  background: #DCDCDC;
  border: 1px solid rgba(0, 0, 0, 0);
  border-bottom: 3px solid #b6b6b6;
  height: 3rem;
  font-size: 0.875rem;
  font-weight: 700;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 0 calc(0.325rem / 2);
  width: 50%;
  color: #333!important;

  &:hover {
    background: #e9e9e9;
    border-bottom: 3px solid #c3c2c2;
    text-decoration: none;
  }

  &:active {
    margin-top: 3px;
    border-bottom: none;
  }

  &:visited {
    color: #333!important;
  }
}

.m3pcom-btn--warning {
  @include naviFlex;
  @include m3Button;
  color: #ffffff!important;
  background: #c81f3d;
  border: 1px solid rgba(0, 0, 0, 0);
  border-bottom: 3px solid #861529;
  height: 3rem;
  font-size: 0.875rem;
  font-weight: 700;
  margin: 0 calc(0.325rem / 2);
  width: 50%;

  &:hover {
    background: #dd2444;
    border-bottom: 3px solid #9c1830;
    text-decoration: none;
  }

  &:active {
    margin-top: 3px;
    border-bottom: none;
  }

  &:visited {
    color: #fff!important;
  }
}

.m3pcom-btn--primary {
  @include naviFlex;
  @include m3Button;
  color: #ffffff!important;
  background: #40509e;
  border: 1px solid rgba(0, 0, 0, 0);
  border-bottom: 3px solid #2a3468;
  height: 3rem;
  font-size: 0.875rem;
  font-weight: 700;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 0 calc(0.325rem / 2);
  width: 50%;

  &:hover {
    background: #4759b0;
    border-bottom: 3px solid #313e7a;
    text-decoration: none;
  }

  &:active {
    margin-top: 3px;
    border-bottom: none;
  }

  &:visited {
    color: #fff!important;
  }
}

.m3pcom-btn--secondary {
  @include naviFlex;
  @include m3Button;
  color: #ffffff!important;
  background: #f9982c;
  border: 1px solid rgba(0, 0, 0, 0);
  border-bottom: 3px solid #d37206;
  height: 3rem;
  font-size: 0.875rem;
  font-weight: 700;
  margin: 0 calc(0.325rem / 2);
  width: 50%;

  &:hover {
    background: #faa445;
    border-bottom: 3px solid #eb7f07;
    text-decoration: none;
  }

  &:active {
    margin-top: 3px;
    border-bottom: none;
  }

  &:visited {
    color: #fff!important;
  }
}

button.m3pcom-btn-more {
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
