@use "../setting/config";
@use "../setting/flex";

.m3pcom-mailmagazine-box {
  padding-top: 3px;
  line-height: 1.5;
  font-size: 0.875rem;
}

.m3pcom-mailmagazine__mail__icon {
  font-size: 1.25rem;
  color: #40509e;
  line-height: 1;
}

.m3pcom-mailmagazine-panel--pc {
  border: 1px solid #9ac;
  background-color: #eceff4;
  padding: 10px 40px;
  margin-top: 10px;
}

.m3pcom-mailmagazine-panel--sp {
  background: #FFF;
  padding: 10px 16px;
  margin-top: 10px;
  position: relative;
}

.m3pcom-mailmagazine-panel-error--pc {
  border: 1px solid #9ac;
  color: #c00;
  font-size: 0.875rem;
  background-color: #eceff4;
  padding: 10px 20px;
  margin-top: 10px;
}

.m3pcom-mailmagazine-panel-error--sp {
  color: #c00;
  font-size: 0.875rem;
  background: #FFF;
  padding: 10px 16px;
  margin-top: 10px;
}
