@use "../setting/config";

.m3pcom * {
  box-sizing: border-box;
}

.m3pcom {
  color: config.$gray;
  font-family: "Hiragino Kaku Gothic Pro","ヒラギノ角ゴ ProN W3","Hiragino Sans","ヒラギノ角ゴシック","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-font-feature-settings: "kern";
  font-feature-settings: "kern";
  -webkit-font-kerning: normal;
  font-kerning: normal;
  word-break: break-all;
}

.atlas-sp-main__logout {
  padding-top: 0 !important;
  background: #fff !important;
}

.m3pcom-atlas-main__open {
  width: 820px;
  float: none;
  margin: 0 auto;
}

.atlas-sp-main_open {
  display: block;
  background: #efefef;
  padding-bottom: 10px;
}

.m3-breadcrumbs {
  display: none;

  @include config.mq(md) {
    display: block;
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: .75rem;
  }
}

.m3pcom a {
  text-decoration: none;
}

.atlas-nav {
  position: static!important;
}

.m3-twbs-modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: #000;
  z-index: 6666;
  width: 100%;
}
