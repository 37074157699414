@use "../setting/config";
@use "../setting/flex";

@mixin naviFlex {
  display: flex;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.m3pcom-listnavi-wrap {
  @include naviFlex;
  background: #FFF;
  height: 60px;
  padding: 0 16px;
  position: relative;

  @include config.mq(xs) {
    padding: 0 40px;
  }
}

.m3pcom-listnavi-heading {
  border-left: 5px solid config.$primary;
  font-size: 1.1875rem;
  padding: 0 8px;
  margin: 0;
  font-weight: 700;
  @include flex.FlexItem;
}

.m3pcom-listnavi-tab {
  @include naviFlex;
  padding: 0 0 0 1.5rem;
  list-style: none;

  @include config.mq(xs) {
    padding: 0 0 0 2.5rem;
  }
}

.m3pcom-listnavi-list {
  @include naviFlex;
  font-size: 0.75rem;
  font-weight: 700;
  height: 60px;
  padding: 0 .25rem;
  position: relative;
  @include flex.FlexItem;

  a {
    color: config.$g-light;

    &:hover {
      text-decoration: none;
    }
  }

  &:hover {

      a {
      color: config.$primary;
    }
  }
}

.m3pcom-listnavi-active {

  &::after {
    content: "";
    width: 100%;
    height: 5px;
    background: #2fabbc;
    position: absolute;
    bottom: -1px;
    left: 0;
    display: block;
  }
}
