@use "../setting/config";

.m3pcom-footernavi-container a {
  color: #333;
}

.m3pcom-footernavi-wrap {
  width: 100%;
  text-align: center;
  background: config.$text-b;
  position: fixed;
  bottom: 0;
  z-index: 8999;

  @include config.mq(sm) {
    text-align: left;
  }
}

.m3pcom-footernavi-inner {
  width: 100%;
  height: 50px;
  display: flex;
  flex-basis: auto;
  flex-shrink: 0;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  margin: 0 auto;
  box-sizing: border-box;

  @include config.mq(sm) {
    width: 720px;
    margin: 0 auto 0 0;
  }

  @include config.mq(xl) {
    width: 780px;
    margin: 0 auto 0 0;
  }
}

.m3pcom-footernavi-body {
  width: 100%;
  padding: 0;
  margin: 0 auto;

  @include config.mq(sm) {
    width: 720px;
    margin: 0 auto 0 0;
  }

  @include config.mq(lg) {
    width: 980px;
    margin: 0 auto;
  }

  @include config.mq(xl) {
    width: 1180px;
    margin: 0 auto;
  }
}

.m3pcom-footernavi-popupwrap {
  position: relative;
}

.m3pcom-footernavi-tag--wrap {
  position: fixed;
  bottom: 50px;
  background: #F0F4F4;
  height: auto;
  width: 100vw;
  filter: drop-shadow(0px -4px 4px rgba(0, 0, 0, 0.05));
  -webkit-transition: all .5s;
    transition: all .3s;
}

.m3pcom-footernavi-tag--inner {
  padding: 1rem 30px;
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 100%;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  @include config.mq(md) {
    width: 720px;
    margin: 0 auto 0 0;
  }

  @include config.mq(xl) {
    width: 780px;
    margin: 0 auto 0 0;
  }
}
