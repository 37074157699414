@use "../setting/config";
@use "../setting/flex";
@use "../mixin/icon";

.m3pcom-detele-category--wrap {
  margin: 0 auto 3px 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.m3pcom-detele-thread-title {
  display: inline;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;

  @include config.mq(sm) {
    font-size: 1.25rem;
  }
}

.m3pcom-detele-text--wrap {
  display: block;
  margin: 0 auto 0.625rem 0;
  @include flex.FlexItem;

  p {
    margin: 1em 0;

    &:last-child {
      margin: 1em 0 0 0;
    }
  }
}

.m3pcom-detele-title--wrap {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.m3pcom-detele-thread-text {
  font-size: 1rem;
  line-height: 1.75;
  font-weight: 500;

  p {
    margin: 1em 0;

    &:last-child {
      margin: 1em 0;
    }
  }
}

.m3pcom-detele-sub {
  font-size: 12px;
  line-height: 1.5;
  color: config.$g-light;
  @include flex.FlexItem;
}

.m3pcom-detele-name {
  font-size: 12px;
  line-height: 1.5;
  color: config.$g-light;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.m3pcom-detele-sub--line {
  margin: 0 3px;
  height: 14px;
  background-color: #E7E7EA;
  width: 1px;
  flex-shrink: 0;
}

.m3pcom-detele-sub--wrap {
  margin: 0 auto 1.25rem auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.m3pcom-detele-text--wrap {
  display: block;
  margin: 0 auto 0.625rem 0;
  @include flex.FlexItem;

  a {
    color: #03a!important;
    font-weight: 700;
    cursor: pointer;
  }
}

.m3pcom-detele-view--wrap {
  padding: 10px 0;
}

.m3pcom-detele-caption {
  font-size: 12px;

  a {
    color: #03a;

    &:hover {
      text-decoration: underline;
    }
  }
}

.m3pcom-detele-title {
  display: inline;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;

  @include config.mq(xs) {
    font-size: 1.125rem;
  }
}

.m3pcom-detele-replay {
  cursor: pointer;
  transition-duration: 0.4s;
  -webkit-transition-duration: .4s;
  -o-transition-duration: 0.4s;

  &:hover {
    opacity: .5;
    text-decoration: underline;
    text-decoration-color: rgba(153, 153, 153, .5);
    transition-duration: 0.4s;
    -webkit-transition-duration: .4s;
    -o-transition-duration: 0.4s;
  }
}

.m3pcom-detele-replay--right {
  margin: -2px 3px 0 0;
}

.m3pcom-detele-order--ar {
  width: 14px;
  height: 10px;
  margin: -4px 8px 0 0;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  line-height: 1;
  display: inline-block;
  align-self: center;
}

.m3pcom-detele-order--ar--up {
  width: 14px;
  height: 10px;
  margin: 6px 8px 0 0;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  line-height: 1;
  transform: rotate(180deg);
  display: inline-block;
  align-self: center;
}

.m3pcom-detele-order--arrtext {
  font-size: 0.875rem;
  margin: 0 1rem 0 0;
  display: inline-block;
}

.m3pcom-detele-order--text {
  font-size: 0.875rem;
}

.m3pcom-mutetext {
  font-size: 0.875rem;
  color: #c00;
  padding: 0 1rem;

  a {
    color: #03a;
  }
}

// reactionここから

.m3pcom-detail-reaction-clicked+.m3pcom-detail-reaction--text {
  font-weight: 700;
  color: config.$primary;
}

.m3pcom-detail-reaction--wrap {
  @include icon.iconFc;
  justify-content: space-between;
}

.m3pcom-detail-reaction {
  padding: 2px 4px;
  cursor: pointer;
}

.m3pcom-detail-reaction-clicked .m3pcom-detail-reaction--after {
  padding: 2px 4px;
  background: config.$icon-o;
  border-radius: 4px;
}

.m3pcom-detail-reaction-complaint .m3pcom-detail-reaction--after {
  opacity: .38;
}

.m3pcom-detail-reaction--text {
  color: config.$icon-o;
  margin: 0;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5;
}

.m3pcom-detail-reaction--item {
  position: relative;
  overflow: hidden;
  width: 33.5px;
  height: 36px;
}

.m3pcom-detail-comment-reaction--wrap {
  position: relative;
  padding: 1rem 0 0 0;

  @include config.mq(sm) {
    padding: 1.5rem 0 0 0;
  }
}

.m3pcom-detail-reaction:hover .m3pcom-detail-reaction--item {
  transition: all ease-in-out 0.25s;
  transform: scale(1.3,1.3) rotate(2deg);
}

.m3pcom-detail-reaction:hover .goodButton-hand {
  animation:goodHand .8s 3 normal forwards running;
}

.m3pcom-detail-reaction:hover .goodButton-heart {
  animation:goodHeart .8s 3 normal forwards running;
}

@keyframes goodHand {
  0% {
    transform: scale(.8,.8);
  }
  50% {
    transform: scale(1,1);
  }
  100% {
    transform: scale(.8,.8);
  }
}

@keyframes goodHeart {
  0% {
    transform: scale(0,0);
  }
  50% {
    transform: translate(0%, -10%) scale(1.3,1.3);
  }
  100% {
    transform: scale(1,1);
  }
}

.m3pcom-detail-reaction:hover .surprisedButton-body {
  animation:surprisedBody .8s 3 normal forwards running;
}

.m3pcom-detail-reaction:hover .surprisedButton-sweat {
  animation:surprisedSweat .8s 3 normal forwards running;
}

.m3pcom-detail-reaction:hover .surprisedButton-mark {
  animation:surprisedMark .8s 3 normal forwards running;
}


@keyframes surprisedBody {
  0% {
    transform: scale(.9,.8);
  }
  50% {
    transform: scale(1,1);
  }
  100% {
    transform: scale(.9,.9);
  }
}

@keyframes surprisedSweat {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translate(0%, -5%);
  }
  100% {
    opacity: 0;
    transform: translate(0%, 5%);
  }
}

@keyframes surprisedMark {
  0% {
    transform: scale(.6,.6);
  }
  50% {
    transform: scale(1.2,1.2);
  }
  100% {
    transform: scale(.8,.8);
  }
}

.m3pcom-detail-reaction:hover .interestedButton-glass {
  animation:interestedGlass .8s 3 normal forwards running;
}

.m3pcom-detail-reaction:hover .interestedButton-star1 {
  animation:interestedStar1 .8s 3 normal forwards running;
}

.m3pcom-detail-reaction:hover .interestedButton-star2 {
  animation:interestedStar2 .8s 3 normal forwards running;
}

@keyframes interestedGlass {
  0% {
    transform: scale(.9,.9);
  }
  50% {
    transform: scale(1,1);
  }
  100% {
    transform: scale(.9,.9);
  }
}

@keyframes interestedPen {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
    transform: rotate(-20deg);
  }
  50% {
    opacity: 1;
    transform: rotate(-10deg);
  }
  75% {
    opacity: 1;
    transform: rotate(-20deg);
  }
  100% {
    opacity: 1;
    transform: rotate(-10deg);
  }
}

@keyframes interestedStar1 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes interestedStar2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.m3pcom-detail-reaction:hover .reactionButton-left {
  transition: all ease-in-out 0.5s;
  animation:yellLeft .8s 3 normal forwards running;
}

.m3pcom-detail-reaction:hover .reactionButton-right {
  transition: all ease-in-out 0.5s;
  animation:yellRight .8s 3 normal forwards running;
}

.m3pcom-detail-reaction_left {
  position: absolute;
  bottom: 0;
  left: 0;
}

.m3pcom-detail-reaction_right {
  position: absolute;
  bottom: 0;
  right: 0;
}

@keyframes yellLeft {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0%, -10%);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes yellRight {
  0% {
    transform: translate(0%, -10%);
  }
  50% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0%, -10%);
  }
}

.m3pcom-detail-reaction:hover .iseeButton-light1 {
  animation:iseeLight1 .8s 3 normal forwards running;
}

.m3pcom-detail-reaction:hover .iseeButton-light2 {
  animation:iseeLight2 .8s 3 normal forwards running;
}

@keyframes iseeLight1 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes iseeLight2 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scale(1.35,1.35);
  }
}

//関連コンテンツ
.m3pcom-detail-related--title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  svg {
    fill: rgb(83,100,113);
    width: 24px;
    height: 24px;
  }

  h3 {
    font-size: 1rem;
    font-weight: 700;
  }
}

.m3pcom-detail-related {
  font-size: .875rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.m3pcom-detail-related--list {
  list-style-position: inside;
  padding: 8px;
  transition: all .3s;

  &:hover {
    background: rgba(122, 190, 199, .2);
    border-radius:4px;
  }
}

.m3pcom-detail-related--listWrap {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 0 -6px;
  width: calc(100% - 14px);
}

.m3pcom-detail-related--flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
}

.m3pcom-detail-related--arr {
  position: relative;
  height: 14px;
  padding: 0;
  margin: 0;
  transform: rotate(-90deg);
  width: 10px;
  line-height: 0;

  svg {
    position: absolute;
    right: 1px;
    height: 12px;
    width: 12px;
  }
}
