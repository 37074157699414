@use "../setting/config";

.m3p-rhsinner {
  padding: 20px;
  box-sizing: border-box;
  list-style-type: none;
  margin: 0;
  font-size: .875rem;

  @include config.mq(xs) {
    padding: 20px 2%
  }

  a {

    img {

      &:hover {
      opacity: 0.6;
      text-decoration: underline;
      }
    }
  }
}

.m3p-login__button {
  display: block;
  margin-top: 20px;
  padding: 13px 14px;
  width: 100%;
  box-sizing: border-box;
  background-color: #40509e;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  text-align: center;
  border-bottom: 3px solid #2a3468;
  color: #fff;
  font-size: 1em;
  cursor: pointer;

  &:hover {
    opacity: .6;
  }
}

.login__button--login {
  background-color: #40509e;
  border-bottom: 3px solid #2a3468;
  margin: 0;

  &:hover {
    background-color: #4759b0;
    border-bottom: 3px solid #313e7a;
  }
}

.m3p-login__button--regist {
  background-color: #f9982c;
  border-bottom: 3px solid #d37206;
  margin: 0;

  &:hover {
    background-color: #faa445;
    border-bottom: 3px solid #eb7f07;
  }
}

.m3p-column-parts__text--most {
  color: #c00;
  font-style: normal;
  font-weight: 700;
}

.m3p-rhs__panel{
  background: #FFF;
  margin-top: 10px;
  border: 1px solid #dedede;
  font-size: 0.875rem;
}

.m3p-text__m {
    font-size: 14px;
    margin: 0;
    line-height: 1.5;
}

.m3p-rhs__20 {
  margin-top: 20px;
  width: 100%;
}

.m3p-10 {
  margin-top: 10px;
  width: 100%;
}

.atlas-rhs__panel{
  background: #FFF;

  a {

    img {

      &:hover {
      opacity: 0.6;
      text-decoration: underline;
      }
    }
  }
}
