@use "../setting/config";

@mixin naviFlex {
  display: flex;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.m3pcom-popover-dot {
  position: relative;
  margin: 7px 0 0 auto;
}

.m3pcom-popover {
  background-color: #fff;
}

.js-popover-menu {
  display: none;
  max-width: 280px;
  padding: 1px;
  text-align: left;
  background-clip: padding-box;
  border-radius: 6px;
  -webkit-box-shadow: rgb(101 119 134 / 20%) 0px 0px 15px, rgb(101 119 134 / 15%) 0px 0px 3px 1px;
  box-shadow: rgb(101 119 134 / 20%) 0px 0px 15px, rgb(101 119 134 / 15%) 0px 0px 3px 1px;
  white-space: normal;
  position: absolute;
  right: 0;
  top: -8px;
  margin-left: -280px;
  overflow: hidden;
  -webkit-animation: fadeIn .8s;
  animation: fadeIn .8s;
  z-index: 888;
}

@-webkit-keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
}

.m3pcom-popover-content {
  padding: 1rem;
  text-align: left;
  transition-duration: 0.4s;
  -webkit-transition-duration: .4s;
  -o-transition-duration: 0.4s;
  background: #FFF;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;

  &:hover {
    opacity: .8;
    background: config.$primary-l-h;
  }

  a:hover {
    text-decoration: none!important;
  }
}
