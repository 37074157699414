
@use "../setting/config";

.m3pcom-ca-sp {
  display: block;

  @include config.mq(md) {
    display: none;
  }
}

.m3pcom-ca-pc {
  display: none;

  @include config.mq(md) {
    display: block;
  }
}
