@use "../setting/config";

@mixin tag {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  flex-basis: auto;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid config.$primary;
  padding: 5px 10px;
  color: config.$primary;
  border-radius: 6px;
}

.m3pcom-tag {
  font-size: 12px;
  font-weight: 600;
  min-width: 50px;
  margin: 4px 0 4px 8px;
  background: #FFF;
  transition-duration: 0.4s;
  -webkit-transition-duration: .4s;
  -o-transition-duration: 0.4s;
  cursor: pointer;
  @include tag;

  a {
    color: config.$primary;
  }

  &:hover {
    background: config.$primary;
    color: #FFF;
  }
}

.m3pcom-tag-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;

  a {
    color: config.$primary!important;

    &:hover {
      color: #FFF!important;
    }
  }
}
