@use "../setting/config";
@use "../setting/flex";

.m3pcom-help-atlas-body {
  min-height: 600px;
  padding-top: 55px;
  padding-bottom: 10px;
  background: #efefef;
  width: 100%;

  @include config.mq(xs) {
    padding-top: 55px;
    padding-bottom: 10px;
    background: #efefef;
    padding-left: 10px;
    padding-right: 10px;
  }

  @include config.mq(md) {
    min-height: 600px;
    padding-top: 0px;
    background: #FFF;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.m3pcom-body-content--bg {
  background: #FFF;
}

.m3pcom-help-atlas-body--top {
  min-height: 600px;
  padding-bottom: 10px;
  background: #efefef;
  width: 100%;

  @include config.mq(xs) {
    padding-bottom: 10px;
  }

  @include config.mq(md) {
    min-height: 600px;
    padding-top: 0px;
    background: #FFF;
    width: 100%;
    margin:  0 auto;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.m3pcom-help-main-container {
  background: #FFF;
  padding: 0 1rem 1rem 1rem;

  @include config.mq(sm) {
    padding: 0;
  }
}

.m3pcom-help-boder--all {
  border: 1px solid transparent;

  @include config.mq(sm) {
    border: 1px solid #dedede;
  }
}

.m3pcom-help-boder--top {
  border-left: 1px solid transparent;
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;

  @include config.mq(sm) {
    border-left: 1px solid #dedede;
    border-top: 1px solid #dedede;
    border-right: 1px solid #dedede;
  }
}

.m3pcom-help-boder--bottom {
  border-top: 1px solid #dedede;
  border-left: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-right: 1px solid transparent;

  @include config.mq(sm) {
    border-top: 1px solid transparent;
    border-left: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
    border-right: 1px solid #dedede;
  }
}

.m3pcom-help-atlas-main {
  width: 100%;
}

.m3pcom-help-list--default {
  margin: 10px 0;
  padding: 0;

  @include config.mq(sm) {
    margin: 20px 0;
  }
}

.m3pcom-help-list--default li {
  font-size: 14px;
}

.m3pcom-help-list--default * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.m3pcom-help-list--first {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  color: #fff;
  background-color: #40509e;
  border-top: 1px solid #fff;
}

.m3pcom-help-list--inner {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  display: none;
  background: #FFF;
}

.m3pcom-help-list--open {
  background: #FFF;

  li {
    border: 1px solid #ccc;
    border-top: none;
  }
}

.m3pcom-help-list--default .m3pcom-help-list--inner li {
  border: 1px solid #ccc;
  border-top: none;
}

.m3pcom-help-list--default li a {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px;
}

.m3pcom-help-arrow {
  width: 14px;
  height: auto;
}

.m3pcom-help-arrow--inner {
  width: 14px;
  height: auto;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.help-btn-first {
  fill: #FFF;
}

.help-btn-inner {
  fill: #40509e;
}

.m3pcom-help-container {
  -ms-flex-preferred-size: auto;
  -ms-flex-negative: 0;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-basis: auto;
  flex-shrink: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  ms-flex-direction: column;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background: #efefef;

  @include config.mq(md) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  @include config.mq(sm) {
    background: #FFF;
  }
}

.m3pcom-help-main {
  width: 100%;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;

  @include config.mq(md) {
    width: 70%;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}

.m3pcom-help__navi {
  position: relative;
  top: 0;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  padding-top: 0;
  width: 100%;
  background: #FFF;

  @include config.mq(md) {
    width: 27%;
    position: sticky;
    padding-top: 10px;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
}

.m3pcom-help__pageindex {
  margin: 0 0 20px 0;
  padding: 0;

  @include config.mq(sm) {
    width: 100%;
  }
}

.m3pcom-help__pageindex {

  .m3pcom-help__navilist {
    width: 100%;
    margin: 0 0 5px 0;
    padding: 0;
    list-style: none;

    @include config.mq(sm) {
      margin: 0 0 10px 0;
    }

    a {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 10px;
      border: 1px solid #dedede;
      background-color: #eceff4;
      color: #333;
      font-size: 12px;

      &:hover {
        text-decoration: none;
        background-color: #fff;
        border: 1px solid #9ac;
      }
    }
  }
}

.m3pcom-help-naviaroow {
  width: 12px;
  height: auto;

  .help-naviarrow {
  fill: #333;
  }
}

.m3pcom-help__heading {
    text-align: center;
    padding: 20px;
    font-size: 1.125rem;
    background: #FFF;

    @include config.mq(sm) {
      width: 100%;
      padding: 50px 20px;
      font-size: 1.5rem;
  }

  h1, h2, h3 {
    margin: 0;
    font-size: inherit;
  }
}

.m3pcom-help__article {
  border-bottom: 1px solid #dedede;
  line-height: 1.8;
  font-size: 14px;
  margin: 0 0 20px 0;
  padding: 10px 0 20px;

  @include config.mq(sm) {
    margin: 0 0 20px 0;
    padding: 10px 0 20px;
  }
}

.m3pcom .m3-heading1 {
  font-size: 1rem;

  @include config.mq(sm) {
    font-size: 1.125rem;
  }
}

.m3pcom .m3-heading--marker {
  margin: 0 0 20px;
}

.m3pcom-heading1 {
  font-size: 1.1875rem;
  line-height: 1.5;
}

.m3-heading--marker {
  border-left: 5px solid #40509e;
  padding-left: 8px;
}

.m3pcom-help__article-body p {
  margin: 1rem 0 0 0;
  padding: 0;
  font-size: 0.875rem;
}

.m3pcom-help__article-body {

  a {
    color: #03a!important;

    &:hover {
      text-decoration: underline;
    }
  }
}

.m3pcom-help-container--1col {
  width: 100%;
  margin: 0;
}

.m3pcom-help__article {
  margin: 0 0 20px 0;
  padding: 10px 0 20px;
  border-bottom: 1px solid #ccc;
  line-height: 1.8;
  font-size: 14px;

  .m3sp-table {
  margin: 10px 0;
  }
}

.m3pcom-help__environment th {
  width: 1%;
  white-space: nowrap;

  @include config.mq(sm) {
    width:120px
  }
}

.m3pcom-help-body-content {
  margin: 0 auto;
  width: 100%;
  padding: 0;
  background: #efefef;
  

  @include config.mq(sm) {
    padding: 10px;
    background-color: #FFF;
  }

  @include config.mq(md) {
    width: 980px;
    padding: 10px 0 0 0;
    background-color: #FFF;
  }
}

.m3pcom-help__tlt {
    background-color: #eceff4;
    text-align: center;
    padding: 50px;
}

.m3pcom-help__tlt h2 {
    margin: 0;
    padding: 0;
    font-size: 24px;
    color: #006;
}
