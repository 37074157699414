@use "../setting/config";

@mixin FlexF {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  flex-basis: auto;
  position: relative;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  @include config.mq(sm) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

@mixin FlexC {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  flex-basis: auto;
  position: relative;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@mixin FlexR {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  flex-basis: auto;
  position: relative;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.m3pcom-ranking-panel {
  width: 100%;
  background: #fff;
  padding: 0;

  @include config.mq(sm) {
    padding: 0 16px 20px 16px;
  }

  @include config.mq(xl) {
    padding: 0 40px 20px 40px;
  }
}

.m3pcom-ranking-wrap {
  @include FlexF;
}

.m3pcom-ranking-list {
  @include FlexC;
  margin: 0;
  padding: 0;
  list-style: none;
  -ms-flex-preferred-size: 50%;
  flex-basis: 48%;
  -ms-flex-negative: 1;
  flex-shrink: 1;

  &:first-child {
    margin: 0;

    @include config.mq(sm) {
      margin: 0;
    }
  }
}

.m3pcom-ranking-list--item {
  padding: 15px 20px;
  border-bottom: 1px solid #dedede;
  width: 100%;
  @include FlexR;

  @include config.mq(sm) {
    padding: 15px 1%;
  }
}

.m3pcom-ranking-list--item>a {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  text-decoration: none !important;
}

.m3pcom-ranking-list--rank {
  width: 22px;
  height: 22px;
  background: #7c95db;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  line-height: 22px;
}

.m3pcom-ranking-list--text {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  width: 100%;
  padding: 0 10px;
  line-height: 1.3;

  a {
    color: #333;
  }
}

.m3pcom-ranking-list--title {
  display: block;
  font-size: 0.9375rem;
  line-height: 1.5;
  font-weight: bold;
  font-style: normal;
}

.m3pcom-ranking-list--category {
  color: #999 !important;
  font-size: 12px;
}

.m3pcom-ranking-list--image {
  overflow: hidden;
  width: 65px;
  height: 50px;
  background: #fafafa;
  border: 1px solid #dedede;
}

.m3pcom-ranking-list--image>img {
  position: relative;
  top: 50%;
  left: 50%;
  width: auto;
  height: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.m3pcom-ranking-list--rank__1st {
  background: #d0a32f;
}

.m3pcom-ranking-list--rank__2nd {
  background: #b9b9b0;
}

.m3pcom-ranking-list--rank__3rd {
  background: #d07149;
}
