@use "../setting/config";
@use "../setting/flex";

@mixin iconF {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
}

@mixin iconW {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@mixin iconFc {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.m3pcom-icon-wrap {
  @include iconF
}

.m3pcom-icon-comment--wrap {
  @include iconW;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  width: 33%;
}

.m3pcom-icon-comment {
  color: config.$icon-o;
  width: 24px;
  height: 22.4px;
}

.icon-basic {
  fill: config.$icon-o;
}

.m3pcom-icon-basic--text {
  color: config.$icon-o;
  margin: 0 0 0 6px;
  font-weight: 400;
  font-size: 0.875rem;
}

.m3pcom-icon-view--wrap {
  @include iconW;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 33%;
}

.m3pcom-icon-view {
  color: config.$icon-o;
  width: 26px;
  height: 25px;
}

.m3pcom-icon-good--wrap {
  @include iconW;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 33%;
}

.m3pcom-icon-good {
  color: config.$pink;
  width: 24px;
  height: 25px;
}

.icon-pink {
  fill: config.$pink;
}

.m3pcom-icon-good--text {
  color: config.$pink;
  margin: 0 0 0 6px;
  font-weight: 400;
  font-size: 0.875rem;
}

.good {
  cursor: pointer;
}

@mixin keyframes($animation-good) {
  @keyframes #{$animation-good} {
    @content;
  }
}
@mixin animation($animation-good) {
  -webkit-animation: $animation-good;
  animation: $animation-good;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@include keyframes(good) {
  0% {
    opacity: 0;
  }
  25% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.4, 1.4);
    transform-origin: center center;
  }

  85% {
    transform: scale(1, 0.8);
    transform-origin: center center;
  }

  100% {
    transform: scale(1.0, 1.0);
  }
}

.clicked {
  @include animation(good 400ms);
}

.m3pcom-icon-wrap--column {
  display: block;
  cursor: pointer;
  @include iconFc
}

.m3pcom-icon-home {
  width: 22.22px;
  height: 20px;
  margin: 0 0 3px 0;
}

.icon-footer {
  fill: #FFF;
}

.m3pcom-icon-footer--text {
  color: #FFF;
  font-weight: 700;
  font-size: 0.625rem;
}

.m3pcom-icon-write {
  width: 18px;
  height: 20px;
}

.m3pcom-icon-tag {
  width: 17.27px;
  height: 20px;
}

.m3pcom-icon-popover {
  transition-duration: 0.4s;
  -webkit-transition-duration: .4s;
  -o-transition-duration: 0.4s;
  cursor: pointer;

  .icon-tool {
    fill: config.$gray;
  }

  .m3pcom-icon-popover--text {
    color: config.$gray;
    margin: 0 0 0 6px;
    @include flex.FlexItem;
  }
}

.m3pcom-icon-delete {
  width: 14px;
  height: 20px;
  @include flex.FlexItem;
}

.m3pcom-icon-report {
  width: 14px;
  height: 22px;
  @include flex.FlexItem;
}

.m3pcom-icon-bad {
  width: 14px;
  height: 22px;
  @include flex.FlexItem;
}

.m3pcom-icon-topnavi {
  width: 16px;
  height: 16px;
  margin: 0 auto;
  @include flex.Flex;
  @include flex.FlexItem;

  svg {
    width: 16px;
    height: auto;
  }
}

.m3pcom-icon-topnavi--text {
  color: config.$primary-l;
  margin: 0 0 0 6px;
  font-weight: 700;
  font-size: 0.75rem;
  @include flex.FlexItem;
}

.m3pcom-icon-naviback {
  width: 18px;
  height: 18px;
  @include flex.FlexItem;
}

.icon-back {
  fill: #000066;
}

.m3pcom-icon-naviback--text {
  color: #000066;
  margin: 0 0 0 6px;
  font-size: 0.75rem;
  @include flex.FlexItem;
}

.icon-close {
  fill: #4F4F4F;
}

.icon-close-wrap {
  width: 12px;
  height: 12px;
  margin: auto;
}

.m3pcom-icon-close {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 4px;
  right: 0;
}

.m3pcom-icon-dot {
  width: 16px;
  height: 24px;
  cursor: pointer;
  margin: -4px auto 0 auto;
}

.icon-dot {
  fill: config.$g-light;
}

.m3pcom-icon-dot--hover {
  position: absolute;
  top: -.55rem;
  left: -.5rem;
  right: -.5rem;
  bottom: 0rem;
  border-radius: 999px;
  cursor: pointer;
  transition-duration: 0.4s;
  -webkit-transition-duration: .4s;
  -o-transition-duration: 0.4s;
  height: 32px;
  width: 32px;

  &:hover {
    background: rgba(122, 190, 199, .1);
  }
}

.m3pcom-icon-replay {
  width: 20px;
  height: 24px;
}

.icon-replay {
  fill: config.$icon-o;
}

// reactionここから

.m3pcom-icon-reaction--wrap {
  @include iconW;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 33%;
}

.m3pcom-icon-reaction {
  color: config.$icon-o;
  width: 24px;
  height: 23.11px;
}
