@use "../setting/config";

.m3pcom-pc-body {
  min-width: 980px!important;
  margin: 0;
  padding: 0;
}

.atlas-header {
  display: block;

  @include config.mq(md) {
    display: block;
  }
}

.atlas-header__content {
  height:55px;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  @include config.mq(md) {
    height:40px;
    margin: 0 auto;
    padding: 0 10px;
    width: 960px
  }

  @include config.mq(xl) {
    width: 1180px;
  }
}

.atlas-sp-header {
  display: block;

  @include config.mq(md) {
    display: block;
  }
}

.atlas-header__p_regist {
  font-size: 0.7rem;
  padding: 0 8px;

  @include config.mq(sm) {
    float: right;
    margin: 10px 0;
  }
}

.atlas-header__p_regist a {
  font-size: 0.875rem;
}

.atlas_p-main {
  display: block;
  background: #efefef;
  padding-top: 0px;
  padding-bottom: 10px;
}

.atlas-nav__main {

  @include config.mq(md) {
    display: block;
    width: 980px;
    margin: 0 auto;
  }

  @include config.mq(xl) {
    width: 1200px;
  }
}

.atlas-nav {

  @include config.mq(md) {
    display: block;
    min-height: 42px;
    border-bottom: 1px solid #d6dcee;
    padding-top: 5px;
    font-family: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ ProN W3", "Hiragino Sans", "ヒラギノ角ゴシック", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", sans-serif;
    font-size: 0.875rem;
    color: #555;
    background-color: #fff;
    position: relative;
    z-index: 6000;
  }
}

.atlas-header__accessory {

  @include config.mq(md) {
    display: block;
    *zoom: 1;
    margin: 0;
    padding: 0;
    float: right;
    list-style: none;
  }
}

.atlas-sp-footer {
  background: #f6f6f6;
}

.body-content {
  width: 100%;
  padding: 0;

  @include config.mq(sm) {
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    *zoom: 1;
  }

  @include config.mq(md) {
    width: 980px;
    margin: 0 auto;
    padding: 10px;
    *zoom: 1;
  }

  @include config.mq(xl) {
    width: 1200px;
    padding: 10px 10px 100px;
  }
}

.atlas-main {

  @include config.mq(sm) {
    float: left;
    width: 720px;
  }

  @include config.mq(xl) {
    float: left;
    width: 780px;
  }
}

.m3pcom-atlas-sp-body .atlas-main {
  width: 100%;
  float: none;
}

.atlas-rhs {
  display: block;
}

.atlas-footer {
  display: block;

  @include config.mq(sm) {
    display: block;
  }
}

.atlas-footer__content {
  width: 100%;

  @include config.mq(md) {
    width: 960px !important;
    margin: 0 auto;
    padding: 0;
  }

  @include config.mq(xl) {
    width: 1180px !important;
  }
}

.atlas-sp-footer__inner {
  font-size: 0.875rem;
  padding: 10px;
}

.atlas-sp-footer__inner p {
  margin: 0;
  padding: 0;
}

.m3sp-pull-right {
  text-align: right;
}

.m3sp-pull-right a {
  text-decoration: none;
}

.atlas-sp-footer small {
  display: block;
  font-size: 0.625rem;
  color: #666;
  border-top: 1px solid #778cc6;
  padding: 20px 10px 70px;
}

.atlas-header__title {
  display: inline-block;
  max-width: 300px;
  max-height: 40px!important;
  overflow: hidden;
  font-size: 0.625rem;
  line-height: 1.2;
  margin: 9px auto 0 auto!important;

  @include config.mq(md) {
    margin: 0!important;
    font-size: 0.875rem;
    max-height: 35px!important;
  }
}

.atlas-header__logo {
  width: 80px;
  margin: 0 0 5px 0!important;
  height: auto!important;

  @include config.mq(md) {
    float: left;
    margin: 7px 12px 0 0!important;
    padding: 0;
  }
}

.logout_menu {
  display: inline-block;
  text-align: center;
  padding: 10px 0 0 15px;
}

.logout_menu_txt {
  font-size: 0.625rem;
  color: #fff;
  margin: 0;
  padding: 0;
}

.m3p_sp_login_icon_login, .m3p_sp_login_icon_regist {

  img {
    width: 20px;
  }
}
