@use "../setting/config";

.m3pcom-category-wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background: #FFF;
  padding: 0;
  margin: 10px 0 0 0;
}

.m3pcom-category-wrap li {
  list-style: none;
}

.m3pcom-category-item {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 33.333%;
  font-size: 0.875rem;
  font-weight: 700;
  color: config.$g-light;
  border-right: 1px solid config.$g-line;
  border-top: 1px solid config.$g-line;
  transition-duration: 0.4s;

  &:hover {
    color: config.$primary;
    background: rgba(47,171,188,.1);
  }

  @include config.mq(sm) {
    width: 16.666%;
    border-bottom: 1px solid config.$g-line;
    border-top: 1px solid transparent;
  }
}

.m3pcom-category-item a {
  padding: 0.625rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex: 1 0 0;
}

.m3pcom-category-item:nth-child(1) {
  border-left: 1px solid transparent;
}

.m3pcom-category-item:nth-child(3n+4) {
  border-left: 1px solid transparent;

  @include config.mq(sm) {
    border-left: 1px solid config.$g-line;
  }
}

.m3pcom-category-item:nth-child(3) {
  border-right: 1px solid transparent;
}

.m3pcom-category-item:nth-last-of-type(-n+3) {
  border-bottom: 1px solid config.$g-line;
}

.m3pcom-category-item:last-child {
  border-right: 1px solid transparent;
}

.m3pcom-category-item.m3pcom-category-item--active {
  color: config.$primary;
  border-bottom: 4px solid config.$primary;
}

.m3pcom-category-caption-title {
  font-weight: 700;
  margin: 0 1rem 0 0;
}

.m3pcom-category-caption-panel {
  width: 100%;
  padding: 10px 16px;
  background: #FFF;
  box-sizing: border-box;
  font-size: 0.75rem;

  @include config.mq(sm) {
    padding: 10px 16px;
    font-size: 0.875rem;
  }

  @include config.mq(xl) {
    padding: 10px 40px;
  }
}
