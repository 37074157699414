@use "../setting/config";
@use "../setting/flex";

.m3p-sns-row {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-preferred-size: auto;
  -ms-flex-negative: 0;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  align-items: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-basis: auto;
  flex-shrink: 0;
  justify-content: space-between;
  position: relative;
  margin: 15px 15px 5px 15px;

  @include config.mq(md) {
    margin: 1rem auto 6px 0;
    width: 380px;
  }

  &::before {
    display: table;
    content: "";
    line-height: 0;
  }

  &::after {
    display: table;
    content: "";
    line-height: 0;
  }
}

.m3p-sns-col {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  margin-left: 2%;

  a {
    color: #FFF!important;
    -webkit-transition: 0.1s ease 0.05s;
    -o-transition: 0.1s ease 0.05s;
    transition: 0.1s ease 0.05s;

    &:hover {
      text-decoration: none!important;
      opacity: .5;
    }
  }

  &:first-child {
    margin-left: 0;
  }
}

.m3p-sns-fb {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background: #1877f2;
  border-radius: 4px;
  color: #fff;
  padding: 10px;
}

.m3p-sns-tw {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background: #000;
  border-radius: 4px;
  color: #fff;
  padding: 10px;
}

.m3p-sns-icon {
  width: 40px;
  margin-right: 8px;

  img {
    vertical-align: -10px;
  }
}

.m3p-sns-text {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;

  p {
    line-height: 1;
    font-size: 1rem;
    font-weight: bold;
    margin: 0;

    span {
      font-size: 0.625rem;
      font-weight: normal;
    }
  }
}
