$text-b: rgba(64, 80, 158, 1);
$primary: rgba(47, 171, 188, 1);
$primary-l: rgba(122, 190, 199, 1);
$primary-l-h:rgba(248, 252, 252, 1);
$gray: rgba(51, 51, 51, 1);
$gray-o: rgba(51, 51, 51, .5);
$g-light: rgba(153, 153, 153, 1);
$g-light-h: rgba(153, 153, 153, .5);
$g-line: rgba(222, 222, 222, 1);
$g-menu: rgba(115, 115, 115, 1);
$orange: rgba(247, 137, 64, 1);
$yellow: rgba(255, 200, 2, 1);
$b-link: rgba(0, 51, 170, 1);
$icon-o: rgba(83, 100, 113, .5);
$pink: rgba(198, 160, 194, 1);

$breakpoints: ('xs': 'screen and (min-width: 481px)', //sp
  'sm': 'screen and (min-width: 769px)', //tablet
  'md': 'screen and (min-width: 959px)', //tablet_landscape
  'lg': 'screen and (min-width: 1025px)', //pc
  'xl': 'screen and (min-width: 1279px)', // wide
) !default;

@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

a {
  text-decoration: none;
  cursor: pointer;
}
