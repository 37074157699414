@use "../setting/config";

.m3p-rhslogo {
  width: 90px;
}

.m3p-rhs-list__number {
  counter-reset: number;
  list-style-type: none !important;
  line-height: 2.2;
  padding: 0;
  margin: 0;
  word-wrap: break-word;
  word-break: normal;
  text-align: justify;

  li {
    position: relative;
    padding: 8px 0 8px 24px;
    line-height: 2.2;
    font-size: 14px;

    &::before {
      position: absolute;
      counter-increment: number;
      content: counter(number);
      display: inline-block;
      background: #f9982c;
      color: #fff;
      font-size: 12px;
      border-radius: 50%;
      left: 0;
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      top: 22px;
      transform: translateY(-50%);
      font-weight: normal;
    }
  }
}

.m3p-rhs__border-dashed {
  border-bottom: 1px dashed #dedede;
}
