@use "../setting/config";
@use "../setting/flex";

@mixin naviFlex {
  display: flex;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.m3pcom-panel {
  width: 100%;
  padding: 30px 16px;
  background: #FFF;
  box-sizing: border-box;

  @include config.mq(sm) {
    padding: 30px 16px;
  }

  @include config.mq(xl) {
    padding: 30px 40px;
  }
}

.m3pcom-panel-detele {
  width: 100%;
  padding: 20px 16px;
  background: #FFF;

  @include config.mq(sm) {
    padding: 30px 16px;
  }

  @include config.mq(xl) {
    padding: 30px 40px;
  }
}

.m3pcom-panel-snippet {
  width: 100%;
  padding: 0;
  background: #FFF;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

.m3pcom-panel-full {
  width: 100%;
  padding: 0;
  background: #FFF;
}

.m3pcom-panel-ca {
  width: 100%;
}

.m3pcom-panel-commentca {
  margin: 10px auto;
}

.m3pcom-panel-boderca {
  border-top: 1px solid #eff3f4;
}

.m3pcom-panel-title {
  @include naviFlex;
  background: #FFF;
  padding: 20px 16px;
  position: relative;

  @include config.mq(xs) {
    padding: 20px 40px;
  }
}

.m3pcom-panel-heading {
  border-left: 5px solid config.$primary;
  font-size: 1.125rem;
  padding: 0 8px;
  margin: 0;
  font-weight: 700;
  @include flex.FlexItem;

  @include config.mq(xs) {
    font-size: 1.1875rem;
  }
}

.m3pcom-panel-narrow {
  width: 100%;
  padding: 1rem;
  background: #fff;
  box-sizing: border-box;

  @include config.mq(xl) {
    padding: 1rem 40px;
  }
}

#ordering_header {
  margin-top: -55px; //Atlasヘッダーの高さ分のpx
  padding-top: 55px; //Atlasヘッダーの高さ分のpx
}

// reactionここから

.m3pcom-panel-thread {
  width: 100%;
  padding: 30px 16px 0 16px;
  background: #FFF;
  box-sizing: border-box;

  @include config.mq(sm) {
    padding: 30px 16px 0 16px;
  }

  @include config.mq(xl) {
    padding: 30px 40px 0 40px;
  }
}
