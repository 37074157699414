@use "../setting/config";

@mixin StrechFlex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.m3pcom-topnavi {
  border-radius: 30px;
  -webkit-box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
  background: #FFF;
  display: inline-block;
  height: 30px;
  border: 1px solid #EFF3F4;
  position: fixed;
  margin: 0 0 0 55%;
  right: 1rem;
  z-index: 999;

  @include config.mq(xs) {
    margin: 0 0 0 55%;
    right: 1rem;
  }

  @include config.mq(sm) {
    margin: 0 0 0 470px;
    right: auto;
  }

  @include config.mq(md) {
    margin: 0 0 0 470px;
  }

  @include config.mq(xl) {
    margin: 0 0 0 520px;
  }
}

.m3pcom-topnavi-wrap {
  @include StrechFlex;
  height: 28px;

  a:hover {
    text-decoration: none;
  }
}

.m3pcom-topnavi-line {
  margin: 3px 0;
  background-color: #E7E7EA;
  width: 1px;
}

.m3pcom-topnavi-left {
  background: #FFF;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  padding: 0 10px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;

  &:hover {
    background: rgba(47, 171, 188, .1);
  }
}

.m3pcom-topnavi-right {
  background: #FFF;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 0 10px;
  cursor: pointer;
  transition-duration: 0.4s;
  -webkit-transition-duration: .4s;
  -o-transition-duration: 0.4s;

  &:hover {
    background: rgba(47, 171, 188, .1);
  }
}

.m3pcom-topnavi-pc {
  display: none;

  @include config.mq(xs) {
    display: inline-block;
  }
}
