@use "../setting/config";
@use "../setting/flex";

@mixin label {
  display: inline-block;
  padding: 2px 4px;
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 1.2;
  width: 36px;
  text-align: center;
  height: 16px;
}

.m3pcom-tool-label--new {
  @include label;
  background: #C77171;
  color: #FFF;
  margin: 0 3px;
  vertical-align: 3px;
}

.m3pcom-tool-label--up {
  @include label;
  background: #5D905D;
  color: #FFF;
  margin: 0 3px;
  vertical-align: 3px;
}

.m3pcom-tool-title--wrap {
  margin: 0 12px 3px 0;
  text-align: left;
  font-size: 0;
}
